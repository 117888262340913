import { firestore } from "../firebase";

//METHODS
export async function initUser(email, callbackDB) {
    if(navigator.onLine)
    {      
        try {
            var query = await firestore.collection('users').doc(email);
            query.onSnapshot(function (snapshot) {     
                let tmpData = snapshot.data();
                if (tmpData)
                {
                    tmpData.userId = email;
                    callbackDB(tmpData);
                }
            });
        }
        catch (e) { console.log("error: " + e); }
    }
}