import firebase from 'firebase/app';
import 'firebase/auth';        // for authentication
//import 'firebase/storage';     // for storage
//import 'firebase/database';    // for realtime database
import 'firebase/firestore';   // for cloud firestore
//import 'firebase/messaging';   // for cloud messaging
import 'firebase/functions';   // for cloud functions


const firebaseConfig = {
    apiKey: "AIzaSyCtCQnMu0rI4MSAIBwqP5Hsxhb2VFZM4V8",
    authDomain: "mylock-541a9.firebaseapp.com",
    databaseURL: "https://mylock-541a9-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "mylock-541a9",
    storageBucket: "mylock-541a9.appspot.com",
    messagingSenderId: "891258656266",
    appId: "1:891258656266:web:21fe22c716239bd00f71f3",
    measurementId: "G-BK29L3X7QV"
};

/*
var firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTHDOMAIN,
    databaseURL: process.env.REACT_APP_BASEURL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
};
*/

firebase.initializeApp(firebaseConfig);
export const auth = firebase.auth();
export const firestore = firebase.firestore();

const app = firebase.app();
export const functions = app.functions("europe-west1");