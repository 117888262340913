import React, { useState, useEffect, useRef } from 'react';
import { createTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { HashRouter, Route, Switch, Redirect } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import { auth, functions } from "./firebase";
import { get, set } from 'idb-keyval';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { isMobile } from "react-device-detect";
import './App.css';

import installedApp from './images/installed_app.png';
import shareIosIcon from './images/share.svg';
var db = require('./tools/DBManager.js');
const allTexts = require('./texts.json');

const version = "6.06";
const theme = createTheme({
  palette: {
    primary: {
      main: '#109e9a',
      light: '#59d0cb',
      dark: '#006f6c',
      contrastText: '#000000',
    },
    secondary: {
      main: '#00023a',
      light: '#2e2c65',
      dark: '#000017',
      contrastText: '#ffffff',
    },
  },
});

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/Login'));
const Register = React.lazy(() => import('./views/Register'));
const Page404 = React.lazy(() => import('./views/Page404'));
const Page500 = React.lazy(() => import('./views/Page500'));

function iOS() {
  return [
    'iPad Simulator',
    'iPhone Simulator',
    'iPod Simulator',
    'iPad',
    'iPhone',
    'iPod'
  ].includes(navigator.platform)
    // iPad on iOS 13 detection
    || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
}

const CircularLoading = (
  <div className="App">
    <div />
    <div className="Main">
      <CircularProgress />
    </div>
    <div />
  </div>
);

let deferredPrompt;
window.addEventListener('beforeinstallprompt', (e) => {
  deferredPrompt = e;
});

export default function App() {
  const [screenSize, setScreenSize] = useState({ w: 320, h: 568 });
  const [loading, setLoading] = useState(true);
  const [installed, setInstalled] = useState(false);
  //const [platform, setPlatform] = useState('');
  const [user, setUser] = useState();
  const [language, setLanguage] = useState('es');
  const [fares, setFares] = useState();
  const [cuppons, setCuppons] = useState();
  const [locations, setLocations] = useState();
  const [loginError, setLoginError] = useState('');
  const [loginInfo, setLoginInfo] = useState('');
  const [registerError, setRegisterError] = useState('');
  const [texts, setTexts] = useState(allTexts.es);
  const [noInstall, setNoInstall] = useState(false);
  const ref = useRef(null)

  const installTheApp = (
    <div className="App">
      <div />
      <div className="Main">
        <div className="install-or-add">
          <img style={{ width: '100%', marginTop: '20%' }} src={installedApp} alt="installedApp" />
          <div className="install-or-add" style={{ paddingBlock: '25px' }}>
            <Typography variant="body2" className="basic-message" style={{ marginBottom: '10px' }}>Instala la aplicación de MyLock en tu pantalla de inicio para un acceso rápido y fácil.</Typography>
            {!iOS() && <Button variant="contained" color="secondary" onClick={async () => {
              if (deferredPrompt !== null) {
                deferredPrompt.prompt();
                const { outcome } = await deferredPrompt.userChoice;
                if (outcome === 'accepted') {
                  deferredPrompt = null;
                }
              }
            }} >Instalar</Button>}
          </div>
          <div className="basic-message" style={{ width: '100%', background: '#e7e7e7', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', padding: '15px' }} >
            {iOS() && <Typography variant="subtitle2">Pulsa <img style={{ height: '25px' }} src={shareIosIcon} alt="myLockLogo" /> y luego 'Añadir a la pantalla de inicio'</Typography>}
            {!iOS() && <Typography variant="subtitle2"> Pulsa 'Instalar' o 'Añadir a la pantalla de inicio' en el menú de opciones.</Typography>}
          </div>
        </div>
      </div>
      <div />
      <br />
      <div className="do-not-install" onClick={() => { setNoInstall(true); console.log("Continuar sin instalar") }}>
        <div className="basic-message" style={{ width: '100%', borderBottomLeftRadius: '10px', borderBottomRightRadius: '10px', padding: '15px' }} >
          <Typography variant="subtitle2">Continuar sin instalar</Typography>
        </div>
      </div>
    </div>
  );

  const appNotAvailable = (
    <div className="App">
      <div />
      <div className="Main">
        <div className="install-or-add">
          <img style={{ width: '100%', marginTop: '20%' }} src={installedApp} alt="installedApp" />
          <div className="install-or-add" style={{ paddingBlock: '25px' }}>
            <Typography variant="subtitle2"> Lo sentimos, la aplicación MyLock solo esta disponble para dispositivos mobiles</Typography>
          </div>
        </div>
      </div>
      <div />
    </div>
  );

  /*function isInstalled() {
    //return true; // TO DEVELOP ON DESKTOP
    return ((true || (
      window.navigator.fullscreen ||
      window.navigator.standalone ||
      window.matchMedia('(display-mode: fullscreen)').matches ||
      window.matchMedia('(display-mode: standalone)').matches)) &&
      isMobile);
  }*/

  /*
  window.addEventListener("appinstalled", event => {
    fireTracking("PWA app installed by user!!! Hurray");
  });
  */

  function handleSetLanguage(language) {
    set('language', language);
    setLanguage(language);
    switch (language) {
      case 'ca':
        setTexts(allTexts.ca);
        break;
      case 'en':
        setTexts(allTexts.en);
        break;
      case 'es':
      default:
        setTexts(allTexts.es);
    }
  }

  async function handleRegister(email, password, phoneNumber, acceptCommunications) {
    setLoading(true);

    try {
      let userCredential = await auth.createUserWithEmailAndPassword(email, password);
      let user = JSON.parse(JSON.stringify(userCredential.user));
      user.phoneNumber = phoneNumber;
      user.acceptCommunications = acceptCommunications;
      //db.initUser(email, setUser);
      setRegisterError('');
      //setLoading(false);

      try {
        let res = await functions.httpsCallable('createUser')({ user });
        if (!res)
          throw new Error("ERROR: <createUserWithEmailAndPassword> Firebase failed error.");
      }
      catch (e) {
        console.log("ERROR: <createUserWithEmailAndPassword> Firebase failed error: " + e);
        throw new Error("ERROR: <createUserWithEmailAndPassword> Firebase failed error: " + e);
      }
    }
    catch (error) {
      console.log("<handleRegister> error: " + error.message);
      setRegisterError(error.message);
      setLoading(false);
      this.history.push("/register");
    };
  }

  async function handleLogin(email, password) {
    setLoading(true);
    auth.signInWithEmailAndPassword(email, password)
      .then((userCredential) => {
        //db.initUser(email, setUser);
        setLoginError('');
      })
      .catch((error) => {
        console.log("<handleLogin> error: " + error.message);
        setLoginError(error.message);
      });
    setLoading(false);
  }

  async function handleLogout() {
    auth.signOut().then(() => {
      console.log("<handleLogin> Sign-out successful.");
      setUser();
    }).catch((error) => {
      console.log("<handleLogin> error: " + error.message);
    });
  }

  async function handleResetPassword(email) {
    auth.sendPasswordResetEmail(email).then(() => {
      setLoginInfo(texts.login.reset_password_sent + email);
      console.log("<handleResetPassword> Password reset email sent.");
    }).catch((error) => {
      setLoginError(error.message);
      console.log("<handleResetPassword> error: " + error.message);
    });
  }

  function refreshFares() {
    functions.httpsCallable('fares')()
      .then(async (response) => setFares(await response.data.fares))
      .catch(e => console.log("farres ERROR:" + e))
  }

  function refreshCuppons(usr) {
    functions.httpsCallable('cuppons')({ cuppons: usr.promos })
      .then(async (response) => {
        setCuppons(await response.data.cuppons)
      })
      .catch(e => console.log("cuppons ERROR:" + e))
  }

  function refreshLocations() {
    functions.httpsCallable('locations')()
      .then(async (response) => setLocations(await response.data.locations))
      .catch(e => console.log("locations ERROR:" + e))
  }

  useEffect(() => {
    const UA = navigator.userAgent;
    const IOS = UA.match(/iPhone|iPad|iPod/);
    //const ANDROID = UA.match(/Android/);
    //setPlatform(IOS ? 'ios' : ANDROID ? 'android' : 'unknown');
    const standalone = window.matchMedia('(display-mode: standalone)').matches;
    setInstalled(!!(standalone || (IOS && !UA.match(/Safari/))));

    auth.onAuthStateChanged((userTmp) => {
      if (userTmp) {
        db.initUser(userTmp.email, async (usr) => {
          if (usr.tickets) usr.tickets.reverse();
          setUser(usr);

          refreshFares();
          refreshCuppons(usr);
          refreshLocations();

          setLoading(false);
        });
      }
      else
        setLoading(false);
    });

    if (ref.current)
      setScreenSize({ w: ref.current.clientWidth, h: ref.current.clientHeight });

    get('language').then(async (lang) => {
      if (lang)
        handleSetLanguage(lang);
    });
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <HashRouter>
        <div className="BackGround" ref={ref}>
          <div />

          <div>
            <React.Suspense fallback={CircularLoading}>

              {loading && CircularLoading}

              {isMobile && <div>
                {!loading && <Switch>
                  {user && <Route path="/" name="Home" render={props => <div className="Canvas"><TheLayout className="Canvas" {...props}
                    screenSize={screenSize}
                    logout={handleLogout}
                    setLanguage={(ln) => handleSetLanguage(ln)}
                    texts={texts}
                    user={user}
                    fares={fares}
                    refreshLocations={refreshLocations}
                    locations={locations}
                    version={version}
                    cuppons={cuppons}
                    language={language} /></div>}
                  />}
                  {!user && (noInstall || installed) && <Route exact path="/Login" name="Login Page" render={props => <div className="Canvas"><Login {...props} texts={texts} loged={handleLogin} reset={handleResetPassword} loginError={loginError} loginInfo={loginInfo} /></div>} />}
                  {!user && (noInstall || installed) && <Route exact path="/register" name="Register Page" render={props => <div className="Canvas"><Register className="Canvas" {...props} texts={texts} registered={handleRegister} registerError={registerError} /></div>} />}
                  {!user && !installed && <Route exact path="/*" name="Install the App">{installTheApp}</Route>}
                  <Route exact path="/404" name="Page 404" render={props => <Page404 {...props} />} />
                  <Route exact path="/500" name="Page 500" render={props => <Page500 {...props} />} />
                </Switch>}
              </div>}

              {!isMobile && appNotAvailable}

            </React.Suspense>

            {!loading && !user && (noInstall || installed) && <Redirect to="/login" />}

          </div>

          <div />

        </div>
      </HashRouter>

      <df-messenger
        intent="Init"
        expand="true"
        wait-open="false"
        df-cx="true"
        location="europe-west1"
        chat-title={texts.chat.open}
        agent-id="3842e7ba-3346-4989-9495-d8126aaa6b18"
        language-code="es"
        style={{
          "--df-messenger-button-titlebar-color": "rgb(16,158,154)",
          "--df-messenger-send-icon": "rgb(16,158,154)",
        }}
      ></df-messenger>

    </ThemeProvider>
  );
}
